/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react';
import { Box, Grid, MenuItem, TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { useSelector } from 'store';
import { IRecordField, IRecordFieldsList, IRecordHeaders, IRoleAccess } from 'ui-component/records/types';
import { RenderInput } from 'ui-component/RecordView/components';
import { IGetRecordTypes } from 'views/CreateRecord/types';
import { QUERY_GET_RECORD_TYPES } from 'graphql/queries/recordTypes';
import { PanelFieldSection, PanelFieldSectionInline } from 'views/CreateRecord/components';
import { useQuery } from '@apollo/client';
import { NON_EDITABLE_FIELDS, getEditableField } from 'ui-component/records/utils/editableFields';
import useMyRole from 'hooks/useMyRole';
import { orderFieldsObj } from '../utils';
import { getDropdownOptionList } from 'ui-component/RecordView/utils';
import { ObjectProperty } from 'views/backoffice/CustomObjects/types';
import { ListValue } from 'views/backoffice/CustomLists/types';
import useMyDropdowns from 'hooks/useMyDropdowns';

interface EditPanelProps {
    recordTypeId?: string;
    isCreate?: boolean;
    onChangeRecordType: (recordTypeId: string) => void;
    baseFieldHeaders: Partial<IRecordFieldsList> | null;
    aditionalFieldHeader: Partial<IRecordFieldsList> | null;
    loadingFields?: boolean;
    fieldAccessList: IRoleAccess[];
    isObjectPanelOpen: boolean;
    handleOpenViewObjectPanel: (field: IRecordField, fieldName: string, editable: boolean) => void;
    inMemoryObjectProperties: { [key: string]: (ObjectProperty & { value: string })[] };
    recordData: IRecordHeaders | null;
    customListValues: Record<number, ListValue[]>;
}

export interface EditPanelForm extends CreatePanelForm {
    id: number;
}

export interface CreatePanelForm {
    recordType: number;
    problemCodeId: number;
    recordNumber: string;
    recordDate: string;
    dueDate: string;
    statusId: number;
    poNumber: string;
    assignedTo: number;
    approvedBy: number;
    [key: string]: any;
}

const EditPanel = ({
    isCreate,
    recordTypeId,
    onChangeRecordType,
    aditionalFieldHeader,
    baseFieldHeaders,
    loadingFields,
    fieldAccessList,
    handleOpenViewObjectPanel,
    inMemoryObjectProperties,
    isObjectPanelOpen,
    recordData,
    customListValues
}: EditPanelProps) => {
    const { problemCodeList, recordStatusList, usersList, targetTenantList } = useMyDropdowns({ recordTypeId: Number(recordTypeId) });

    const { data: recordTypeData } = useQuery<IGetRecordTypes>(QUERY_GET_RECORD_TYPES);

    const recordTypeList = recordTypeData?.getRecordTypes || [];
    const roleId = useMyRole();
    const { drawerOpen } = useSelector((state) => state.menu);
    // Objects Control

    const {
        control,
        resetField,
        watch,
        formState: { errors },
        setValue
    } = useFormContext<CreatePanelForm | EditPanelForm>();

    const recordTypeSelected = watch('recordType');

    const allFieldsHeaders = orderFieldsObj({ ...baseFieldHeaders, ...aditionalFieldHeader }, fieldAccessList);

    useEffect(() => {
        if (recordTypeSelected) onChangeRecordType(String(recordTypeSelected));
    }, [onChangeRecordType, recordTypeSelected]);

    if (isObjectPanelOpen) return <></>;

    return (
        <Grid container data-testid="edit-form" sx={{ paddingBottom: '90px' }}>
            <Grid container data-testid="edit-form" sx={{ paddingBottom: '20px' }}>
                <Box id="edit-form" sx={{ width: '100%' }}>
                    {isCreate && (
                        <>
                            {drawerOpen ? (
                                <PanelFieldSection isRecordViewer noPadding title="Record Type *">
                                    <Controller
                                        name="recordType"
                                        control={control}
                                        rules={{ required: true }}
                                        defaultValue={0}
                                        render={({ field }) => (
                                            <TextField
                                                variant="outlined"
                                                select
                                                error={!!errors.recordType}
                                                helperText={errors.recordType?.message}
                                                fullWidth
                                                disabled={!!recordTypeId}
                                                {...field}
                                            >
                                                {recordTypeList.map((el) => (
                                                    <MenuItem key={el.id} value={Number(el.id)}>
                                                        <em>{el.name}</em>
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        )}
                                    />
                                </PanelFieldSection>
                            ) : (
                                <PanelFieldSectionInline noPadding title="Record Type *">
                                    <Controller
                                        name="recordType"
                                        control={control}
                                        rules={{ required: true }}
                                        defaultValue={0}
                                        render={({ field }) => (
                                            <TextField
                                                variant="outlined"
                                                select
                                                sx={{ textAlign: 'left' }}
                                                error={!!errors.recordType}
                                                helperText={errors.recordType?.message}
                                                fullWidth
                                                disabled={!!recordTypeId}
                                                {...field}
                                            >
                                                {recordTypeList.map((el) => (
                                                    <MenuItem key={el.id} value={Number(el.id)}>
                                                        <em>{el.name}</em>
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        )}
                                    />
                                </PanelFieldSectionInline>
                            )}
                        </>
                    )}

                    {!loadingFields &&
                        !!Object.keys(allFieldsHeaders || {}).length &&
                        Object.entries(allFieldsHeaders || {})
                            .filter(([key]) => !(NON_EDITABLE_FIELDS as ReadonlyArray<string>).includes(key))
                            .map(([key, value]) => {
                                const isCustomList = value?.listType;
                                const options = isCustomList
                                    ? (customListValues[value.listType.id] || []).filter(({ enabled }) => enabled)
                                    : getDropdownOptionList(
                                          key as any,
                                          recordTypeList,
                                          recordStatusList,
                                          problemCodeList,
                                          usersList,
                                          targetTenantList
                                      );

                                return (
                                    <RenderInput
                                        key={key}
                                        resetField={resetField}
                                        name={key}
                                        control={control}
                                        fieldData={value as IRecordField}
                                        errors={errors}
                                        options={options}
                                        isCustomList={isCustomList}
                                        disabled={!getEditableField(key, fieldAccessList, Number(recordTypeId), roleId)}
                                        isRequired={value?.required}
                                        setValue={setValue}
                                        handleOpenViewObjectPanel={handleOpenViewObjectPanel}
                                        inMemoryObjectProperties={inMemoryObjectProperties}
                                    />
                                );
                            })}
                </Box>
            </Grid>
        </Grid>
    );
};

export default EditPanel;
