/* eslint-disable no-nested-ternary */
import dayjs from 'dayjs';
import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, Checkbox, Grid, IconButton, Link, List, Skeleton, Tooltip, Typography } from '@mui/material';
import { ObjectProperty } from 'views/backoffice/CustomObjects/types';
import { CheckBoxOutlineBlankOutlined, CheckBoxOutlined, Edit, HighlightOffOutlined } from '@mui/icons-material';
import { HoverAlert } from 'ui-component/third-party/HoverAlert';

interface ObjectPanelViewProps {
    objectName: string;
    loadingObjectProperties: boolean;
    handleClosePanel: () => void;
    handleOpenEditPanel: () => void;
    objectProperties: (ObjectProperty & { value: string })[];
    isRecordViewer?: boolean;
    renderEditButton?: boolean;
    isListObject?: boolean;
    alwaysShowButtons?: boolean;
    listObjectWarning?: () => void;
}

const ObjectPanelView = ({
    objectName,
    loadingObjectProperties,
    handleClosePanel,
    handleOpenEditPanel,
    objectProperties,
    isRecordViewer = false,
    renderEditButton = true,
    isListObject = false,
    alwaysShowButtons = false,
    listObjectWarning = () => {}
}: ObjectPanelViewProps) => {
    const theme = useTheme();
    const defaultValues: { [key: string]: string } = {};
    objectProperties.forEach((item) => {
        defaultValues[item.name] = item.value;
    });

    return (
        <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }} position="relative">
            {!isRecordViewer && (
                <Box sx={{ padding: '12px', paddingBottom: '0', paddingLeft: 0, height: '10%' }}>
                    <Grid container sx={{ display: 'flex', alignItems: 'center' }}>
                        <Grid item xs={10}>
                            <Typography
                                gutterBottom
                                sx={{ fontWeight: '500', fontSize: '16px', color: theme.palette.secondary.main, marginBottom: 0 }}
                            >
                                {objectName}
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <IconButton onClick={handleClosePanel}>
                                <HighlightOffOutlined fontSize="medium" />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Box>
            )}
            <Box
                sx={{
                    paddingX: '12px',
                    paddingTop: '0',
                    paddingBottom: '2rem',
                    height: isRecordViewer ? 'calc(100% - 64px)' : '80%',
                    overflow: 'auto'
                }}
            >
                {loadingObjectProperties ? (
                    <>
                        <List>
                            {[1, 2, 3, 4, 5].map((el) => (
                                <Grid component="li" key={el} container item xs={12} ml={2} mr={1} sx={{ my: '20px' }}>
                                    <Grid item xs={5}>
                                        <Skeleton height={20} animation="wave" />
                                    </Grid>
                                    <Grid item xs={8} mt={1}>
                                        <Skeleton height={30} animation="wave" />
                                    </Grid>
                                </Grid>
                            ))}
                        </List>
                    </>
                ) : (
                    <>
                        {objectProperties.map((item, index) => (
                            <Box sx={{ marginTop: '2rem' }} key={item.id}>
                                <Grid container sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Grid item xs={6}>
                                        <Typography gutterBottom sx={{ fontSize: '12px', fontWeight: '500', color: '#858585' }}>
                                            {item.name}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        {item.dataType === 'url' && (
                                            <Link
                                                style={{ textDecoration: 'none', color: '#000' }}
                                                href={
                                                    item.value.includes('http') || item.value.includes('https')
                                                        ? item.value
                                                        : `https://${item.value}`
                                                }
                                                target="_blank"
                                            >
                                                <Typography
                                                    sx={{
                                                        fontSize: '14px',
                                                        fontWeight: '500',
                                                        color: '#000',
                                                        textDecoration: 'underline'
                                                    }}
                                                >
                                                    {item.value}
                                                </Typography>
                                            </Link>
                                        )}
                                        {item.dataType === 'checkbox' && (
                                            <Checkbox
                                                defaultChecked
                                                checked={item.value === 'true'}
                                                disabled
                                                sx={{
                                                    padding: 0,
                                                    color: theme.palette.primary[400],
                                                    '&.Mui-checked': { color: theme.palette.primary.dark },
                                                    '& .MuiSvgIcon-root': { fontSize: 30 }
                                                }}
                                                icon={<CheckBoxOutlineBlankOutlined />}
                                                checkedIcon={<CheckBoxOutlined />}
                                            />
                                        )}
                                        {item.dataType === 'date' && (
                                            <Typography
                                                sx={{
                                                    fontSize: '14px',
                                                    fontWeight: '500',
                                                    color: '#000'
                                                }}
                                            >
                                                {item.value && dayjs(item.value).format('YYYY-MM-DD')}
                                            </Typography>
                                        )}
                                        {item.dataType === 'datetime' && (
                                            <Typography
                                                sx={{
                                                    fontSize: '14px',
                                                    fontWeight: '500',
                                                    color: '#000'
                                                }}
                                            >
                                                {item.value && dayjs(item.value).format('YYYY-MM-DD hh:mm a')}
                                            </Typography>
                                        )}
                                        {item.dataType !== 'url' &&
                                            item.dataType !== 'date' &&
                                            item.dataType !== 'datetime' &&
                                            item.dataType !== 'checkbox' && (
                                                <Typography
                                                    sx={{
                                                        fontSize: '14px',
                                                        fontWeight: '500',
                                                        color: '#000'
                                                    }}
                                                >
                                                    {item.value}
                                                </Typography>
                                            )}
                                    </Grid>
                                </Grid>
                            </Box>
                        ))}
                    </>
                )}
            </Box>
            {(renderEditButton || alwaysShowButtons || isListObject) && (
                <Box
                    sx={{
                        position: 'absolute',
                        bottom: 0,
                        padding: '12px',
                        background: 'white',
                        width: '100%',
                        borderTop: `1px solid #D9D9D9`,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'end'
                    }}
                >
                    <Box sx={{ paddingRight: isRecordViewer ? '0' : '1rem' }}>
                        <Tooltip
                            slotProps={{
                                tooltip: {
                                    sx: !renderEditButton ? { backgroundColor: 'transparent !important' } : undefined
                                }
                            }}
                            followCursor={!renderEditButton}
                            title={renderEditButton ? 'Edit' : <HoverAlert type="warning" />}
                        >
                            <span>
                                <IconButton
                                    disabled={!renderEditButton}
                                    aria-label="edit"
                                    sx={{
                                        backgroundColor:
                                            renderEditButton && !isListObject
                                                ? theme.palette.secondary.main
                                                : `${theme.palette.primary[300]} !important`,
                                        '&:hover': { backgroundColor: theme.palette.secondary.main }
                                    }}
                                    onClick={() => (isListObject ? listObjectWarning() : handleOpenEditPanel())}
                                >
                                    <Edit sx={{ color: renderEditButton ? '#FFF' : '#D9D9D9' }} />
                                </IconButton>
                            </span>
                        </Tooltip>
                    </Box>
                </Box>
            )}
        </Box>
    );
};

export default ObjectPanelView;
